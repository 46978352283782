import { TextInput } from '@components/form';
import { Group } from '@components/layout';
import { Stack } from '@mui/material';
import { debounce } from 'lodash';
import posthog from 'posthog-js';
import { FilterChips } from './FilterChips';
import { FilterMenu } from './FilterMenu';
import { DeckardFilter, SearchDetailTypes } from './types';

type SearchAndFilterProps<T> = {
  context: string;
  filter: DeckardFilter<T>;
  placeholder?: string;
  searchDetail: SearchDetailTypes;
  variant?: 'primary' | 'secondary';
  setFilter: (filter: DeckardFilter<T>) => void;
};

export const SearchAndFilter = <T,>({
  context,
  filter,
  placeholder,
  searchDetail,
  variant = 'primary',
  setFilter,
}: SearchAndFilterProps<T>) => {
  const debounceTextInput = debounce((value: string) => {
    const newFilter = {
      ...filter,
      texts: { ...filter['texts'], value: value ? value.trim().split(' ') : [] },
    };
    setFilter(newFilter);
    posthog.capture('user_search-and-filter', { type: 'search', context, filter: newFilter });
  }, 400);

  const clearFilterKey = (key: string) => {
    const newFilter = {
      ...filter,
      [key]: { type: filter[key].type, value: [], display: [] },
    };
    setFilter(newFilter);
    posthog.capture('user_search-and-filter', { type: 'clear', context, filter: newFilter });
  };

  const onFilterChange = (newFilter: DeckardFilter<T>) => {
    setFilter(newFilter);
    posthog.capture('user_search-and-filter', { type: 'filter', context, filter: newFilter });
  };

  const updateFilter = (key: string, newValue: unknown) => {
    const newFilter = { ...filter, [key]: { ...filter[key], value: newValue } };
    setFilter(newFilter);
    posthog.capture('user_search-and-filter', { type: 'filter', context, filter: newFilter });
  };

  return (
    <Stack gap={2} width="100%">
      <Group>
        <TextInput
          fullWidth
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          placeholder={placeholder ?? 'Search by name, description, or tags'}
          onChange={(e) => debounceTextInput(e.target.value)}
        />
        <FilterMenu
          filter={filter}
          searchDetail={searchDetail}
          onFilterChange={onFilterChange}
          variant={variant}
        />
      </Group>
      <FilterChips
        filter={filter}
        variant={variant}
        clearFilterKey={clearFilterKey}
        updateFilter={updateFilter}
      />
    </Stack>
  );
};
